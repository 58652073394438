import { call, put, takeLatest } from 'redux-saga/effects';
import { getHeaders, request, serialize } from '../../../utils/fetch';
import { ProjectFilterActionTypes } from './actionTypes';
import { actions } from './actions';
import { UserFilterPage } from './types';

export function* watchProjectFilterSaga() {
  yield takeLatest(
    ProjectFilterActionTypes.FetchSavedProjectFilters,
    apiFetchProjectFiltersSaga,
  );
}

function* apiFetchProjectFiltersSaga() {
  const requestPayload = {
    page: UserFilterPage.ProjectPage,
  };
  const headers: any = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).get(`/filters?${serialize(requestPayload)}`),
  );
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.setSavedProjectFilters(apiRes.data));
  }
}
