import { put, takeLatest } from "redux-saga/effects";
import { getJwt } from "../../../crud/auth.crud";
import { postRequestWrapper } from "../../../utils/fetch";
import { actions as projectsActions } from "../projects/actions";
import { actions } from './actions';
import { actionTypes } from './actionTypes';
import _isFunction from 'lodash/isFunction';

export function* watchProjectTagsSaga() {
    yield takeLatest(actionTypes.FetchUserProjectTags, apiFetchUserProjectTagsSaga);
    yield takeLatest(actionTypes.AddProjectTags, apiAddProjectTagsSaga);
    yield takeLatest(actionTypes.DeleteProjectTag, apiDeleteProjectTagSaga);
    yield takeLatest(actionTypes.UpdateProjectTag, apiUpdateProjectTagSaga);
}

function* apiFetchUserProjectTagsSaga(action) {
    yield put(actions.setLoadingProjectTag(true));
    const requestPayload = {};
    const token = yield getJwt();
    const apiRes = yield postRequestWrapper("/project-tags", token, requestPayload);
    if (apiRes && apiRes.status === "OK") {
      yield put(actions.setUserProjectTags(apiRes.data));
    }
    yield put(actions.setLoadingProjectTag(false));
}

function* apiAddProjectTagsSaga(action) {
    yield put(actions.setAddingProjectTag(true));
    const requestPayload = {
      tags: action.payload.tags,
    };
    const token = yield getJwt();
    const apiRes = yield postRequestWrapper("/project-tags/add", token, requestPayload);
    if (apiRes && apiRes.status === "OK") {
        if (_isFunction(action.cb)) {
            action.cb(null);
        }
        yield put(actions.addUserProjectTags(apiRes.data));
    }
    yield put(actions.setAddingProjectTag(false));
}

function* apiDeleteProjectTagSaga(action) {
    const requestPayload = {
      tag_id: action.payload.tag_id,
    };

    const token = yield getJwt();
    const apiRes = yield postRequestWrapper("/project-tags/delete", token, requestPayload);
    if (apiRes && apiRes.status === "OK") {
      yield put(actions.removeProjectTag(apiRes.data));
      yield put(projectsActions.deleteTagsForAllKeywords(apiRes.data));
      yield put(projectsActions.deleteTagsForAllProjects(apiRes.data));
      if (_isFunction(action.cb)) {
        action.cb(null);
      }
    }
}

function* apiUpdateProjectTagSaga(action) {
    yield put(actions.setAddingProjectTag(true));
    const requestPayload = action.payload;
    const token = yield getJwt();
    const apiRes = yield postRequestWrapper("/project-tags/update", token, requestPayload);
    if (apiRes && apiRes.status === "OK") {
        if (_isFunction(action.cb)) {
            action.cb(null);
        }
        yield put(actions.updateUserProjectTag(apiRes.data));
        yield put(projectsActions.updateTagsForAllProjects(apiRes.data));
    }
    yield put(actions.setAddingProjectTag(false));
}