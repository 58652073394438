import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ProjectFilterActionTypes } from './actionTypes';
import { ProjectFilterAction } from './actions';
import { FilterStateType, ProjectFilters, UserFilter } from './types';

export enum ProjectFilterType {
  Marketplace = 'marketplace',
  Label = 'label',
}

type ProjectFilterReduxState = {
  filters: ProjectFilters;
  savedFilters: UserFilter[];
  expandedFilter: ProjectFilterType | null;
  modalShowing: boolean;
};

export const DefaultFilterState: ProjectFilters = {
  projectLabels: {
    labelIds: [],
    filterState: FilterStateType.Contains,
  },
  filterMarketplaces: {
    marketplaces: [],
    filterState: FilterStateType.Contains,
  },
};

const INITIAL_STATE: ProjectFilterReduxState = {
  filters: DefaultFilterState,
  savedFilters: [],
  expandedFilter: null,
  modalShowing: false,
};

export const reducer = persistReducer(
  {
    storage,
    key: 'projectFilters',
    whitelist: ['filters'],
  },
  (
    state: ProjectFilterReduxState = INITIAL_STATE,
    action: ProjectFilterAction,
  ) => {
    switch (action.type) {
      case ProjectFilterActionTypes.SetProjectFilters: {
        return {
          ...state,
          filters: action.payload,
        };
      }
      case ProjectFilterActionTypes.ClearProjectLabels: {
        return {
          ...state,
          filters: {
            ...state.filters,
            projectLabels: DefaultFilterState.projectLabels,
          },
        };
      }
      case ProjectFilterActionTypes.ClearMarketplaces: {
        return {
          ...state,
          filters: {
            ...state.filters,
            filterMarketplaces: DefaultFilterState.filterMarketplaces,
          },
        };
      }
      case ProjectFilterActionTypes.SetSavedProjectFilters: {
        return {
          ...state,
          savedFilters: action.payload,
        };
      }
      case ProjectFilterActionTypes.SetProjectFilterModalShowing: {
        return {
          ...state,
          modalShowing: action.payload,
        };
      }
      case ProjectFilterActionTypes.ExpandProjectFilterType: {
        return {
          ...state,
          expandedFilter: action.payload,
        };
      }
      case ProjectFilterActionTypes.AddSavedProjectFilter: {
        return {
          ...state,
          savedFilters: [...state.savedFilters, action.payload.projectFilter],
        };
      }
      case ProjectFilterActionTypes.RemoveSavedProjectFilter: {
        return {
          ...state,
          savedFilters: state.savedFilters.filter(
            (filter) => filter.filterName !== action.payload,
          ),
        };
      }
      case ProjectFilterActionTypes.ResetProjectFilters: {
        return INITIAL_STATE;
      }

      default:
        return state;
    }
  },
);
