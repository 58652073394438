import { ProjectFilterActionTypes } from './actionTypes';
import { ProjectFilterType } from './reducer';
import { ProjectFilters, UserFilter } from './types';

export type ProjectFilterAction = {
  type: ProjectFilterActionTypes;
  payload: any;
};

export const actions = {
  setProjectFilters: (payload: ProjectFilters) => ({
    type: ProjectFilterActionTypes.SetProjectFilters,
    payload,
  }),
  clearProjectLabels: () => ({
    type: ProjectFilterActionTypes.ClearProjectLabels,
  }),
  clearMarketplaces: () => ({
    type: ProjectFilterActionTypes.ClearMarketplaces,
  }),
  fetchSavedProjectFilters: () => ({
    type: ProjectFilterActionTypes.FetchSavedProjectFilters,
  }),
  setSavedProjectFilters: (payload: UserFilter[]) => ({
    type: ProjectFilterActionTypes.SetSavedProjectFilters,
    payload,
  }),
  addSavedProjectFilter: (payload: AddSavedProjectFilterPayload) => ({
    type: ProjectFilterActionTypes.AddSavedProjectFilter,
    payload,
  }),
  removeSavedProjectFilter: (payload: string) => ({
    type: ProjectFilterActionTypes.RemoveSavedProjectFilter,
    payload,
  }),
  expandProjectFilterType: (payload: ProjectFilterType) => ({
    type: ProjectFilterActionTypes.ExpandProjectFilterType,
    payload,
  }),
  setProjectFilterModalShowing: (payload: boolean) => ({
    type: ProjectFilterActionTypes.SetProjectFilterModalShowing,
    payload,
  }),
  reset: () => ({
    type: ProjectFilterActionTypes.ResetProjectFilters,
  }),
};

export type AddSavedProjectFilterPayload = {
  projectFilter: UserFilter;
};
