export enum ProjectFilterActionTypes {
  SetProjectFilters = '[SetProjectFilters] Action',
  ResetProjectFilters = '[ResetProjectFilters] Action',
  ClearProjectLabels = '[ClearProjectLabels] Action',
  ClearMarketplaces = '[ClearMarketplaces] Action',
  FetchSavedProjectFilters = '[FetchSavedProjectFilters] Action',
  SetSavedProjectFilters = '[SetSavedProjectFilters] Action',
  AddSavedProjectFilter = '[AddSavedProjectFilter] Action',
  RemoveSavedProjectFilter = '[RemoveSavedProjectFilter] Action',
  ExpandProjectFilterType = '[ExpandProjectFilterType] Action',
  SetProjectFilterModalShowing = '[SetProjectFilterModalShowing] Action',
}
