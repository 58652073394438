import { put, takeLatest } from "redux-saga/effects";
import { getJwt } from "../../../crud/auth.crud";
import { postRequestWrapper } from "../../../utils/fetch";
import { actions as projectsActions } from "../projects/actions";
import { actions } from './actions';
import { actionTypes } from './actionTypes';
import _isFunction from 'lodash/isFunction';

export function* watchKeywordTagsSaga() {
    yield takeLatest(actionTypes.FetchUserTags, apiFetchUserTagsSaga);
    yield takeLatest(actionTypes.AddTags, apiAddTagsSaga);
    yield takeLatest(actionTypes.DeleteTag, apiDeleteTagSaga);
    yield takeLatest(actionTypes.UpdateTag, apiUpdateTagSaga);
}

function* apiFetchUserTagsSaga(action) {
    yield put(actions.setLoadingTag(true));
    const requestPayload = {
      project_id: action.payload.projectId,
    };
    const token = yield getJwt();
    const apiRes = yield postRequestWrapper("/keyword-tags", token, requestPayload);
    if (apiRes && apiRes.status === "OK") {
      yield put(actions.setUserTags(apiRes.data));
    }
    yield put(actions.setLoadingTag(false));
}

function* apiAddTagsSaga(action) {
    yield put(actions.setAddingTag(true));
    const requestPayload = {
      tags: action.payload.tags,
    };
    const token = yield getJwt();
    const apiRes = yield postRequestWrapper("/keyword-tags/add", token, requestPayload);
    if (apiRes && apiRes.status === "OK") {
        if (_isFunction(action.cb)) {
            action.cb(null);
        }
        yield put(actions.addUserTags(apiRes.data));
    }
    yield put(actions.setAddingTag(false));
}

function* apiDeleteTagSaga(action) {
    const requestPayload = {
      project_id: action.payload.projectId,
      tagId: action.payload.tagId,
    };
    const token = yield getJwt();
    const apiRes = yield postRequestWrapper("/keyword-tags/delete", token, requestPayload);
    if (apiRes && apiRes.status === "OK") {
      yield put(actions.removeTag(apiRes.data));
      yield put(projectsActions.deleteTagsForAllKeywords(apiRes.data));
      if (_isFunction(action.cb)) {
        action.cb(null);
      }
    }
}

function* apiUpdateTagSaga(action) {
    yield put(actions.setAddingTag(true));
    const requestPayload = action.payload;
    const token = yield getJwt();
    const apiRes = yield postRequestWrapper("/keyword-tags/update", token, requestPayload);
    if (apiRes && apiRes.status === "OK") {
        if (_isFunction(action.cb)) {
            action.cb(null);
        }
        yield put(actions.updateUserTag(apiRes.data));
        yield put(projectsActions.updateTagsForAllKeywords(apiRes.data));
    }
    yield put(actions.setAddingTag(false));
}