import { actionTypes } from './actionTypes';

export const actions = {
  addProject: (payload, cb) => ({
    type: actionTypes.AddProject,
    payload,
    cb,
  }),
  setLoading: payload => ({
    type: actionTypes.SetLoading,
    payload,
  }),
  fetchProjects: (payload?: any) => ({
    type: actionTypes.FetchProjects,
    payload,
  }),
  setProjects: payload => ({
    type: actionTypes.SetProjects,
    payload,
  }),
  fetchProjectDetail: payload => ({
    type: actionTypes.FetchProjectDetail,
    payload,
  }),
  updateProjectDetail: payload => ({
    type: actionTypes.UpdateProjectDetail,
    payload,
  }),
  trackASIN: payload => ({
    type: actionTypes.TrackASIN,
    payload,
  }),
  trackKeyword: payload => ({
    type: actionTypes.TrackKeyword,
    payload,
  }),
  trackKeywordSuccess: payload => ({
    type: actionTypes.TrackKeywordSuccess,
    payload,
  }),
  trackASINSuccess: payload => ({
    type: actionTypes.TrackASINSuccess,
    payload,
  }),
  setIsGeneratingProject: payload => ({
    type: actionTypes.SetIsGeneratingProject,
    payload,
  }),
  generatingSuccess: payload => ({
    type: actionTypes.GeneratingSuccess,
    payload,
  }),
  fetchRankTracker: payload => ({
    type: actionTypes.FetchRankTracker,
    payload,
  }),
  updateRankTracker: payload => ({
    type: actionTypes.UpdateRankTracker,
    payload,
  }),
  rowClicked: payload => ({
    type: actionTypes.RowClicked,
    payload,
  }),
  deleteProject: (payload, cb) => ({
    type: actionTypes.DeleteProject,
    payload,
    cb,
  }),
  deleteProjectSuccess: payload => ({
    type: actionTypes.DeleteProjectSuccess,
    payload,
  }),
  setSuccessTrackRecord: payload => ({
    type: actionTypes.SetSuccessTrackRecord,
    payload,
  }),
  setLoadingDetail: payload => ({
    type: actionTypes.SetLoadingDetail,
    payload,
  }),
  addAsins: (payload, cb) => ({
    type: actionTypes.AddAsins,
    payload,
    cb,
  }),
  updateAsins: payload => ({
    type: actionTypes.UpdateAsins,
    payload,
  }),
  setPrimaryAsin: payload => ({
    type: actionTypes.SetPrimaryAsin,
    payload,
  }),
  addKeywordTrackerData: payload => ({
    type: actionTypes.AddKeywordTrackerData,
    payload,
  }),
  setLoadingProductForProject: payload => ({
    type: actionTypes.SetLoadingProductForProject,
    payload,
  }),
  fetchRankTrackerData: payload => ({
    type: actionTypes.FetchRankTrackerData,
    payload,
  }),
  updateRankTrackerData: payload => ({
    type: actionTypes.UpdateRankTrackerData,
    payload,
  }),
  setLoadingKeywordForProject: payload => ({
    type: actionTypes.SetLoadingKeywordForProject,
    payload,
  }),
  fetchParticularKeywordData: payload => ({
    type: actionTypes.FetchParticularKeywordData,
    payload,
  }),
  updateKeywordRankValuesData: payload => ({
    type: actionTypes.UpdateKeywordRankValuesData,
    payload,
  }),
  deleteKeywordTrackerData: payload => ({
    type: actionTypes.DeleteKeywordTrackerData,
    payload,
  }),
  removeKeywordTrackerData: payload => ({
    type: actionTypes.RemoveKeywordTrackerData,
    payload,
  }),
  updateProject: payload => ({
    type: actionTypes.UpdateProject,
    payload,
  }),
  updateProjectSuccess: payload => ({
    type: actionTypes.UpdateProjectSuccess,
    payload,
  }),
  setUpdatedProjectId: payload => ({
    type: actionTypes.SetUpdatedProjectId,
    payload,
  }),
  setAsinMessage: payload => ({
    type: actionTypes.SetAsinMessage,
    payload,
  }),
  fetchProjectDetails: payload => ({
    type: actionTypes.FetchProjectDetails,
    payload,
  }),
  updateResultsProject: payload => ({
    type: actionTypes.UpdateResultsProject,
    payload,
  }),
  setTotalKeywords: payload => ({
    type: actionTypes.SetTotalKeywords,
    payload,
  }),
  deleteAsinFromProject: payload => ({
    type: actionTypes.DeleteAsinFromProject,
    payload,
  }),
  removeAsinFromProject: payload => ({
    type: actionTypes.RemoveAsinFromProject,
    payload,
  }),
  setUpdatedPrimaryAsin: payload => ({
    type: actionTypes.SetUpdatedPrimaryAsin,
    payload,
  }),
  setInvalidAsin: payload => ({
    type: actionTypes.SetInvalidAsin,
    payload,
  }),
  fetchKeywordsData: payload => ({
    type: actionTypes.FetchKeywordsData,
    payload,
  }),
  updateKeywordsData: payload => ({
    type: actionTypes.UpdateKeywordsData,
    payload,
  }),
  addKeywordToExistProject: (payload, cb) => ({
    type: actionTypes.AddKeywordToExistProject,
    payload,
    cb,
  }),
  addKeywordToProject: (payload, cb) => ({
    type: actionTypes.AddKeywordToProject,
    payload,
    cb,
  }),
  addKeywordToProjectSuccess: payload => ({
    type: actionTypes.AddKeywordToProjectSuccess,
    payload,
  }),
  addAsinToExistProject: (payload, cb) => ({
    type: actionTypes.AddAsinToExistProject,
    payload,
    cb,
  }),
  addAsinToProject: (payload, cb) => ({
    type: actionTypes.AddAsinToProject,
    payload,
    cb,
  }),
  addAsinToProjectSuccess: payload => ({
    type: actionTypes.AddAsinToProjectSuccess,
    payload,
  }),
  addingToProject: payload => ({
    type: actionTypes.AddingToProject,
    payload,
  }),
  fetchSeasonalityData: payload => ({
    type: actionTypes.FetchSeasonalityData,
    payload,
  }),
  updateSeasonalityData: payload => ({
    type: actionTypes.UpdateSeasonalityData,
    payload,
  }),
  setVariants: payload => ({
    type: actionTypes.SetVariants,
    payload,
  }),
  fetchRelevantKeywords: payload => ({
    type: actionTypes.FetchRelevantKeywords,
    payload,
  }),
  updateRelevantKeywords: payload => ({
    type: actionTypes.UpdateRelevantKeywords,
    payload,
  }),
  trackSuccess: payload => ({
    type: actionTypes.TrackSuccess,
    payload,
  }),
  clearRelevantKeywords: payload => ({
    type: actionTypes.ClearRelevantKeywords,
    payload,
  }),
  addKeywordAndAsinToProject: (payload, cb) => {
    return ({
      type: actionTypes.AddKeywordAndAsinToProject,
      payload,
      cb,
    });
  },
  addKeywordAndAsinToProjectSuccess: payload => ({
    type: actionTypes.AddKeywordAndAsinToProjectSuccess,
    payload,
  }),
  setLoadingKeywordRanks: payload => ({
    type: actionTypes.SetLoadingKeywordRanks,
    payload,
  }),
  fetchKeywordRanks: (payload, cb) => {
    return ({
      type: actionTypes.FetchKeywordRanks,
      payload,
      cb,
    });
  },
  fetchKeywordRanksSuccesses: payload => ({
    type: actionTypes.FetchKeywordRanksSuccesses,
    payload,
  }),
  detachTag: (payload) => ({
    type: actionTypes.DetachTag,
    payload,
  }),
  updateTagsForKeyword: (payload) => ({
    type: actionTypes.UpdateTagsForKeyword,
    payload,
  }),
  updateTagsForProject: (payload) => ({
    type: actionTypes.UpdateTagsForProject,
    payload,
  }),
  attachTag: (payload) => ({
    type: actionTypes.AttachTag,
    payload,
  }),
  attachDetachTags: (payload, cb) => ({
    type: actionTypes.AttachDetachTags,
    payload,
    cb,
  }),
  attachDetachProjectTags: (payload, cb) => ({
    type: actionTypes.AttachDetachProjectTags,
    payload,
    cb,
  }),
  updateTagsForAllKeywords: (payload) => ({
    type: actionTypes.UpdateTagsForAllKeywords,
    payload,
  }),
  deleteTagsForAllKeywords: (payload) => ({
    type: actionTypes.DeleteTagsForAllKeywords,
    payload,
  }),
  updateTagsForAllProjects: (payload) => ({
    type: actionTypes.UpdateTagsForAllProjects,
    payload,
  }),
  deleteTagsForAllProjects: (payload) => ({
    type: actionTypes.DeleteTagsForAllProjects,
    payload,
  }),
  setShownTags: (payload) => ({
    type: actionTypes.SetShownTags,
    payload,
  }),
  setDefaultProjectAdditionSuccesses: () => ({
    type: actionTypes.SetDefaultProjectAdditionSuccesses,
  }),
  updateKeyword: (payload) => ({
    type: actionTypes.UpdateKeyword,
    payload,
  }),
  fetchASINOverview: (payload) => ({
    type: actionTypes.FetchASINOverview,
    payload,
  }),
  fetchASINOverviewSuccesses: (payload) => ({
    type: actionTypes.FetchASINOverviewSuccesses,
    payload,
  }),
  setLoadingRanksForProject: (payload) => ({
    type: actionTypes.SetLoadingRanksForProject,
    payload,
  }),
  fetchCurrentRanks: (payload) => ({
    type: actionTypes.FetchCurrentRanks,
    payload,
  }),
  fetchCurrentRanksSuccesses: (payload) => ({
    type: actionTypes.FetchCurrentRanksSuccesses,
    payload,
  }),
  fetchBSRRanks: (payload) => ({
    type: actionTypes.FetchBSRRanks,
    payload,
  }),
  fetchBSRRanksSuccesses: (payload) => ({
    type: actionTypes.FetchBSRRanksSuccesses,
    payload,
  }),
  setFetchedRank: (payload) => ({
    type: actionTypes.SetFetchedRank,
    payload,
  }),
  setSearchProject: (payload) => ({
    type: actionTypes.SetSearchProject,
    payload,
  }),
  setSelectedProject: (payload) => ({
    type: actionTypes.SetSelectedProject,
    payload,
  }),
};
