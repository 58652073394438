export const userFilterAdapter = userFilters => {
  const result = [...userFilters];
  result.forEach(filter => {
    filter.data.forEach(eachFilter => {
      switch (eachFilter.name) {
        case 'Keyword':
          eachFilter.title = 'keyword';
          break;
        case 'No. of keywords':
          eachFilter.title = 'numberOfKeywords';
          break;
        case 'No. of Keywords':
          eachFilter.title = 'numberOfKeywords';
          break;
        case 'No. of Words':
          eachFilter.title = 'numberOfKeywords';
          break;
        case 'Keyword Clicks':
          eachFilter.title = 'clicks';
          break;
        case 'Keyword Sales':
          eachFilter.title = 'sales';
          break;
        case 'Keyword Conversion Rate':
          eachFilter.title = 'conv_rate';
          break;
        case 'DSTR':
          eachFilter.title = 'daily_giveaway';
          break;
        case 'Top ASIN':
          eachFilter.title = 'top_asin';
          break;
        case 'Top ASIN Conv. Rate':
          eachFilter.title = 'top_asin_conv_rate';
          break;
        case 'Top ASIN Conversion':
          eachFilter.title = 'top_asin_conv_rate';
          break;
        case 'Top ASIN Sales':
          eachFilter.title = 'top_asin_weekly_sales';
          break;
        case 'Top ASIN Clicks':
          eachFilter.title = 'top_asin_weekly_clicks';
          break;
        case 'KPS':
          eachFilter.title = 'kps';
          break;
        case 'Relevancy Score':
          eachFilter.title = 'relevancy_score';
          break;
        case 'Relevancy':
          eachFilter.title = 'relevancy_score';
          break;
        case 'ASIN Organic Rank':
          eachFilter.title = 'asin_organic_rank';
          break;
        case 'ASIN Sponsored Rank':
          eachFilter.title = 'asin_sponsored_rank';
          break;
        case 'ASIN In Top 3':
          eachFilter.title = 'week_asin_in_top_3';
          break;
        case 'ASIN in Top 3':
          eachFilter.title = 'week_asin_in_top_3';
          break;
        case 'Weeks in Top 3':
          eachFilter.title = 'week_asin_in_top_3';
          break;
        case 'ASIN':
          eachFilter.title = 'asin';
          break;
        case 'Title':
          eachFilter.title = 'title';
          break;
        case 'Price':
          eachFilter.title = 'price';
          break;
        case 'Review':
          eachFilter.title = 'reviews';
          break;
        case 'Reviews':
          eachFilter.title = 'reviews';
          break;
        case 'Rating':
          eachFilter.title = 'rating';
          break;
        case 'Keyword on Top 10':
          eachFilter.title = 'keywordsInTop10';
          break;
        case 'Keywords in Top 10':
          eachFilter.title = 'keywordsInTop10';
          break;
        case 'Latest BSR':
          eachFilter.title = 'latestBsr';
          break;
        case 'Top Level Category':
          eachFilter.title = 'topLevelCategory';
          break;
        case 'Label':
          eachFilter.title = 'label';
          eachFilter.value =
            eachFilter?.value && eachFilter.value.toString().split(',');
          break;
        case 'Keyword Conversion':
          eachFilter.title = 'conv_rate';
          break;
        case 'ASIN Distribution':
          eachFilter.title = 'sales_dist';
          break;
        case 'Sales Distribution':
          eachFilter.title = 'sales_dist';
          break;
        case 'Trend':
          eachFilter.title = 'four_week';
          break;
        case 'Organic Rank':
          eachFilter.title = 'organic_rank';
          break;
        case 'Sponsored Rank':
          eachFilter.title = 'sponsored_rank';
          break;
        case 'ASIN Ranked In Top 10':
          eachFilter.title = 'asinsInTop10';
          break;
        case 'ASINs Ranked In Top 10':
          eachFilter.title = 'asinsInTop10';
          break;
        case 'ASINs Ranked in Top 10':
          eachFilter.title = 'asinsInTop10';
          break;
        case 'Sales':
          eachFilter.title = 'salesvolume';
          break;
        case 'Brand':
          eachFilter.title = 'brand';
          break;
        case 'Visibility':
          eachFilter.title = 'visibility';
          break;
        default:
          break;
      }
    });
  });
  return result;
};
