import { Marketplace } from 'app/utils/marketplaces';

export enum FilterStateType {
  Contains = 'Contains',
  DoesNotContain = 'DoesNotContain',
}

export type ProjectFilters = {
  projectLabels: ProjectLabelFilterState;
  filterMarketplaces: ProjectMarketplaceFilterState;
};

export type ProjectMarketplaceFilterState = {
  marketplaces: Marketplace[];
  filterState: FilterStateType;
};

export type ProjectLabelFilterState = {
  labelIds: number[];
  filterState: FilterStateType;
};

export type UserFilter = {
  filterName: string;
  page: UserFilterPage;
  data: UserFilterData[];
};

export enum UserFilterPage {
  ProjectPage = 'Projects',
  KeywordsTab = 'KeywordsTab',
  ASINTab = 'ASINTab',
  RankTrackerTab = 'RankTrackerTab',
}

export type UserFilterData = {
  name: string;
  operator: UserFilterOperator;
  type: 'normal';
  value: string;
};

export enum UserFilterOperator {
  Include = 'include',
  Exclude = 'exclude',
  GreaterThan = '>',
  GreaterThanOrEqualTo = '>=',
  LessThan = '<',
  LessThanOrEqualTo = '<=',
  EqualTo = '=',
  Between = 'between',
}
